import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Container,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const AssignProduct = () => {
  const navigate = useNavigate();
  const [newProduct, setNewProduct] = useState({
    resource_person: "",
    // department: "",
    department_id: "", // Initialize grade_id state
    product_ids: [],
  });

  const [error, setError] = useState(""); // State for error handling
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [products, setProducts] = useState([]);
  const [alreadySelectedProducts, setAlreadySelectedProducts] = useState([]);
  const [resourcePerson, setResourcePerson] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch teachers based on sub-category
  // useEffect(() => {
  //   const fetchResourcePerson = async () => {
  //     try {
  //       const token = getToken();
  //       if (!token) {
  //         console.log("Access Denied");
  //       }
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND}/api/assign_product/getallresourceperson`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const resourcePersonData = response.data.data;
  //       // setResourcePerson(resourcePersonData || []);
  //       console.log("resoursepersons: ", response.data.data);

  //       // const filteredTeachers = resourcePersonData.filter(
  //       //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
  //       // );
  //       // setTeacherFilter(filteredTeachers);
  //     } catch (error) {
  //       console.error("Error fetching teachers:", error);
  //     }
  //   };
  //   fetchResourcePerson();
  // }, []);

  // list resourse person by deapartment
  useEffect(() => {
    const fetchResoursePersonbydeaprtment = async () => {
      if (!newProduct.department_id) {
        return;
      }
      try {
        console.log("department Id   :", newProduct.department_id);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/resoursePersonBydept/${newProduct.department_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setResourcePerson(response.data.data);
        // set(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchResoursePersonbydeaprtment();
  }, [newProduct.department_id]);

  useEffect(() => {
    const fetchAllgrades = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/grade`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setAllGrades(data.data || []); // Set grades from the response
      }
    };
    fetchAllgrades();
  }, []);

  // Fetch courses based on selected department
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    // if (!newProduct.grade_id) return;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/allproducts`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setProducts(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  // already selected products by resourceperson
  useEffect(() => {
    const fetchProductsbyresourceperson = async () => {
      if (!newProduct.resource_person && !newProduct.department_id) {
        return;
      }
      try {
        console.log("department Id   :", newProduct.department_id);

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/productsByResoursePerson/${newProduct.resource_person}/byDept/${newProduct.department_id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setAlreadySelectedProducts(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProductsbyresourceperson();
  }, [newProduct.resource_person, newProduct.department_id]);

  // fetch products by department
  useEffect(() => {
    const fetchProductsByDept = async () => {
      if (!newProduct.department_id) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        if (newProduct.department_id === "select_all") {
          // Fetch all courses when "Select All" is selected
         fetchProducts();
        }else{
          const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/byDept/${newProduct.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setProducts(data.data || []);
          console.log("Courses related gradeId: ", data.data);
          setNewProduct.department_id = "";
        }
        }
        
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProductsByDept();
  }, [newProduct.department_id]);

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        console.log(response.data); // Log the data to check its structure
        if (Array.isArray(response.data.data)) {
          setDepartments(response.data.data || []);
        } else {
          console.error("Expected an array but received:", response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching departments", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  // Handle course selection
  const handleCourseChange = (courseId) => {
    setNewProduct((prevState) => {
      const selectedCourses = prevState.courses.includes(courseId)
        ? prevState.courses.filter((id) => id !== courseId) // Remove course if already selected
        : [...prevState.courses, courseId]; // Add course if not selected
      return { ...prevState, courses: selectedCourses };
    });
  };

  // Handle class selection
  const handleClassChange = (gradeId, division) => {
    setNewProduct((prevState) => {
      const currentGrades = [...prevState.grades];
      const index = currentGrades.findIndex((grade) => grade.id === gradeId);
      if (index > -1) {
        // If already selected, remove it
        currentGrades.splice(index, 1);
      } else {
        // Add if not selected
        currentGrades.push({ id: gradeId, division });
      }
      return { ...prevState, grades: currentGrades };
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const token = getToken();
    if (!token) {
      console.log("Unauthorized User");
    }

    // Validation: Check if at least one product is selected
    if (newProduct.product_ids.length === 0) {
      setError("Please select at least one product.");
      return;
    }

    if(!newProduct.department_id || newProduct.department_id==="select_all" || !newProduct.resource_person){
      alert("Fill all the fields");
      return;
    }

    setError(""); // Clear error if validation passes
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/add`,
        newProduct,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        // Store success message in sessionStorage
        sessionStorage.setItem(
          "AddAssignProductSuccessMessage",
          "product assigned added successfully"
        );
        setNewProduct({
          resource_person: "",
          // department: "",
          department_id: "", // Initialize grade_id state
          product_ids: [],
        });
        // Redirect to Labnames list
        navigate("/school/assigned-product-list");
      }
    } catch (err) {
      console.error(err);
      // Optionally handle error
    }
  };

  const handleCancel = () => {
    navigate('/school/assigned-product-list'); // Navigate to the desired route on cancel
  };

  return (
    <Container maxWidth="100%">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.200",
          padding: "10px",
        }}
      >
        <Typography variant="h6">Assign Products</Typography>
        {/* <Button variant="contained" color="primary">
          + Create
        </Button> */}
      </Box>

      <Grid container spacing={1} sx={{ marginTop: "20px", width: "100%" }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Departments</InputLabel>
            <Select
              value={newProduct.department_id}
              onChange={(e) => {
                setNewProduct({ ...newProduct, department_id: e.target.value });
                setProducts([]); // Uncomment if needed
              }}
              label="Department"
              aria-required="true"
            >
              {/* {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name
                    ? department.department_name
                    : null}
                </MenuItem>
              ))} */}
              <MenuItem value="select_all">Select All</MenuItem>
              {departments.map((department, index) => (
                
                <MenuItem key={index} value={department.department_id}>
                  
                  {department.department_name}{" "}
                  {/* Display the department name */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant="body1" color="#000">
            Select Resource Person
          </Typography>

          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ backgroundColor: "#fff" }}>
              Select Resource Person
            </InputLabel>
            <Select
              value={newProduct.resource_person}
              onChange={(e) =>
                setNewProduct({
                  ...newProduct,
                  resource_person: e.target.value,
                })
              }
              label="Teacher"
              aria-required="true"
            >
              {resourcePerson.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name ? item.name : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" variant="outlined">
            <Typography variant="">Selected products</Typography>
            {alreadySelectedProducts.length > 0 ? (
              alreadySelectedProducts.map((item) => (
                <div key={item._id}>
                  {item.product ? (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ paddingLeft: "0" }}>
                            <Typography>{item.product.product_name}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <span>Product information not available</span>
                  )}
                </div>
              ))
            ) : (
              <Typography>No products selected earlier</Typography>
            )}
          </FormControl>
        </Grid>

        {/* Conditionally render the product section if a department is selected */}
        {newProduct && (
          <Grid item xs={12} md={6} display="flex" justifyContent="center">
            <FormControl component="fieldset" required>
              <Typography variant="body" mb={2}>
                Select Product
              </Typography>
              {products
                // Filter out products that are already selected
                .filter((product) => {
                  return !alreadySelectedProducts.some(
                    (selectedProduct) =>
                      selectedProduct.product._id === product._id
                  );
                })
                // Map the remaining products for selection
                .map((product) => (
                  <FormControlLabel
                    key={product._id}
                    control={
                      <Checkbox
                        checked={newProduct.product_ids.includes(product._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setNewProduct({
                              ...newProduct,
                              product_ids: [
                                ...newProduct.product_ids,
                                product._id,
                              ],
                            });
                          } else {
                            setNewProduct({
                              ...newProduct,
                              product_ids: newProduct.product_ids.filter(
                                (id) => id !== product._id
                              ),
                            });
                          }
                        }}
                      />
                    }
                    label={
                      product && product.product_name
                        ? product.product_name
                        : "Unnamed Product"
                    }
                  />
                ))}
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Box mt={3}>
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message */}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          ADD
        </Button>
        <Button variant="contained" sx={{mx:2, backgroundColor:'#e0e0e0'}} onClick={handleCancel}>
            CANCEL
          </Button>
      </Box>
    </Container>
  );
};

export default AssignProduct;
