import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import {
  Typography,
  Box,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  Paper,
  DialogContent,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import axios from "axios";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../../utils/AuthUtils";
import "./DisplayUnit_student.css";

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DisplayUnit() {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(0);
  const [unit, setUnit] = useState(null);
  const [contentData, setContentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef(null);
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [assessmentEnabledData, setAssessmentEnabledData] = useState([]);
  const [Knoweldgecheckdata, setKnoweldgecheckdata] = useState([]);

  const navigate = useNavigate();

  const { gradename, course_name, chapterindex, chapter_namee, unitnamee } =
    location.state || {};

  // States for image modal
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchUnit = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/unit/ui/${id}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        console.log(response.data);

        // Check if the response has unitsPlans property
        if (response.data && response.data.unitsPlans) {
          setContentData(response.data.unitsPlans);
        } else {
          setError("No units plans found");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch unit data");
      } finally {
        setLoading(false);
      }
    };

    fetchUnit();
  }, []);

  useEffect(() => {
    const fetchAssessmentEnable = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("access denied");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/getenableassessment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(
          "fffffffffffffffffffffffffffffffffffffffffff",
          response.data
        );
        const Data = response.data.data;
        // setAssessmentEnabledData(Data);

        const activeKnowledgeChecks = Data.filter(
          (check) => check.status === 1
        );
        console.log("activeKnowledgeChecks: ", activeKnowledgeChecks);
        // console.log("active knowledge checks: ", activeKnowledgeChecks);

        setAssessmentEnabledData(activeKnowledgeChecks);

        return activeKnowledgeChecks;
        // Check if the response has unitsPlans property
        // if (response.data && response.data.unitsPlans) {
        //   setContentData(response.data.unitsPlans);
        // } else {
        //   setError("No units plans found");
        // }
      } catch (err) {
        setError(err.message || "Failed to fetch unit data");
      } finally {
        setLoading(false);
      }
    };

    fetchAssessmentEnable();
  }, []);

  const KnowledgeCheckButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#d0312d",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#d0312d",
    },
  }));

  const handleKnowledgeCheckClick = async (idd) => {
    console.log("idddddddddddddd: ", idd);
    // to check if the assessment already submitted by the student
    try {
      const token = getToken();
      if (!token) {
        console.log("access denied");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/alreadysubmittedassessment/${idd}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("already submitted", response.data.data);
      const Knoweldgecheckdata = response.data.data;
      setKnoweldgecheckdata(Knoweldgecheckdata);
      let assid = ""; // Declare assid outside the loop

      Knoweldgecheckdata.forEach((item) => {
        assid = item._id; // Assign the _id value
      });

      // Check if the data array has items and navigate accordingly
      if (Knoweldgecheckdata.length > 0) {
        //  alert(gradename);
        navigate(`/student/unit-assessment-List/${id}/${assid}`, {
          state: {
            gradename,
            course_name,
            chapterindex,
            chapter_namee,
            unitnamee,
          },
        });
      } else {
        // Navigate to the alternative route
        navigate(`/student/do-assessment/${idd}`);
      }
    } catch {
      console.log("error");
    }

    // setRedirect(true);
  };

  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };
  const projectRedierct = () => {
    navigate(`/projectuploads/${id}`);
  };
  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  const toggleFullScreen = () => {
    if (containerRef.current) {
      if (document.fullscreenElement) {
        setIsFullScreen(false);
        document.exitFullscreen();
      } else {
        containerRef.current
          .requestFullscreen()
          .then(() => {
            setIsFullScreen(true);
          })
          .catch((err) => {
            console.log("hii");
          });
      }
    }
  };

  const CustomCard = styled(Card)(() => ({
    maxWidth: 200,
    maxHeight: 200,
    textAlign: "center",
    backgroundColor: "#f0f0f0",
    margin: "2px",
  }));

  const CountButton = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
  }));
  // Styles for the larger image
  const LargeImage = styled("img")({
    width: "70%",
    height: "auto",
    margin: "20px auto",
    display: "block",
  });

  // Button styles
  const ButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginTop: "20px",
  });

  const TriangleListItem = styled(ListItem)(({ theme }) => ({
    position: "relative",
    paddingLeft: "24px", // Adjust the padding to make room for the triangle
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      borderColor: "#fcd19e",
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      borderLeft: "10px solid black", // Adjust the color and size of the triangle here
    },
  }));

  const ImageCarousel = styled(Box)({
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    gap: "16px",
    padding: "16px 0",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& img": {
      minWidth: "calc(100% / 3 - 16px)", // 3 images per view, considering the gap
      maxWidth: "calc(100% / 3 - 16px)",
      height: "auto",
      borderRadius: "8px", // Add some styling to the images
      objectFit: "cover",
      cursor: "pointer", // Add pointer cursor to indicate clickable images
    },
  });

  const ImagePopupCarousel = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    // Function to handle when an image is clicked
    const handleClickOpen = (imageSrc) => {
      setSelectedImage(imageSrc);
      setOpen(true);
    };

    // Function to handle closing the modal
    const handleClose = () => {
      setOpen(false);
      setSelectedImage("");
    };
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Clock styles
  const ClockContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    border: "5px solid #e0e0e0",
    textAlign: "center",
    margin: "20px auto",
    position: "relative",
  });

  const TimeText = styled(Typography)({
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  });

  // Counter styles
  const TimerContainer = styled(Box)({
    width: "80%",
    margin: "20px auto",
    padding: "16px",
    backgroundColor: "#e0e0e0",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  });

  const ImageBox = styled(Box)({
    minWidth: "300px",
    height: "200px",
    backgroundColor: "#e0e0e0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
  });

  function Clock() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");
      const seconds = String(time.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    };
    return (
      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#000",
          color: "#00FF00",
          padding: "10px",
          borderRadius: "8px",
          fontSize: "24px",
          fontFamily: "monospace",
          fontWeight: "bold",
          zIndex: 1000,
        }}
      >
        {formatTime(time)}
      </Box>
    );
  }

  const ImageGrid = styled(Box)(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(12, 1fr)", // 12-column grid to handle various image spans
    "& img": {
      width: "100%",
      height: "auto",
      borderRadius: theme.shape.borderRadius,
      objectFit: "cover",
      cursor: "pointer",
    },
  }));
  return (
    <>
      <Container
        maxWidth="xl"
        ref={containerRef}
        sx={{
          width: isFullScreen ? "100%" : "100%",
          height: isFullScreen ? "100%" : "",
          backgroundColor: isFullScreen ? "#fff" : "",
          display: isFullScreen ? "flex" : "",
          flexDirection: isFullScreen ? "column" : "",
          justifyContent: isFullScreen ? "flex-start" : "",
          alignItems: isFullScreen ? "center" : "",
          overflowY: isFullScreen ? "auto" : "",
          maxHeight: isFullScreen ? "100%" : "",
          transition: isFullScreen ? "all 0.3s ease-in-out" : "",
          padding: isFullScreen ? "25px" : "25px",
        }}
      >
        <Box sx={{ backgroundColor: "#9e503d", height: "auto" }}>
          <Container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                // height: "auto",
                width: "100%",
                padding: "10px",
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ fontSize: "18px", color: "#ffffff !important" }}
              >
                {gradename}
                <ArrowRightIcon sx={{ color: "#fff" }} />{course_name}
                <ArrowRightIcon sx={{ color: "#fff" }} />Chapter {chapterindex}: {chapter_namee}
                <ArrowRightIcon sx={{ color: "#fff" }} /> {unitnamee}
                {/* <ArrowRightIcon/> PERIOD 1 */}
              </Typography>
            </Box>
          </Container>
        </Box>
        {!contentData ||
        !contentData.units_content ||
        contentData.units_content.length === 0 ? (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          contentData.units_content.map((item, index) => {
            switch (item.type) {
              case "unitHeading":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#f1f1f1",
                      height: "auto", // Change height to auto to adjust based on the content
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px", // Add some right padding for spacing
                      paddingTop: "10px", // Optional: add padding to avoid text sticking to edges
                      paddingBottom: "10px", // Optional: add padding to avoid text sticking to edges
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Container>
                      <Typography
                        variant="h3"
                        sx={{
                          wordWrap: "break-word", // Ensures long words break into a new line
                          overflow: "hidden", // Prevents overflow
                          textOverflow: "ellipsis", // Adds "..." if text overflows
                          whiteSpace: "normal", // Ensures the text wraps onto the next line
                        }}
                      >
                        {item.data}
                      </Typography>
                    </Container>
                  </Box>
                );
              case "Heading1":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#bb6767",
                      color: "#fff",
                      padding: 1,
                      // margin: "20px",
                      textAlign: "center",
                      // borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "Heading2":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffddb4",
                      padding: 1,
                      // margin: "20px auto",
                      borderRadius: "10px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "Subheading1":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffebd2",
                      padding: 1,
                      // margin: "20px auto",
                      // borderRadius: "10px",
                      marginTop: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                      // sx={{ marginBottom: "0px!important" }}
                    />
                  </Box>
                );
              case "Subheading2":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffd8a84a",
                      padding: 1,
                      // margin: "20px auto",
                      // borderRadius: "10px",
                      marginTop: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "paragraph":
                return (
                  <Box
                    key={index}
                    Width={"85%"}
                    sx={{
                      marginTop: "20px",
                      padding: "20px",
                      color: "#000",
                      backgroundColor: "#fff",
                    }}
                  >
                    <style>
                      {`
                        .ql-size-small {
                          font-size: 12px;
                        }

                        .ql-size-large {
                          font-size: 20px;
                        }

                        .ql-size-huge {
                          font-size: 30px;
                        }

                        .ql-align-justify {
                          text-align: justify;
                        }

                        .ql-align-center {
                          text-align: center;
                        }

                        .ql-align-right {
                          text-align: right;
                        }

                        .ql-font-monospace {
                          font-family: monospace;
                        }
                      `}
                    </style>
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "pdf":
                return (
                  <Box
                    className="nandhu"
                    key={index}
                    width="100%"
                    sx={{ marginleft: "0px !important" }}
                  >
                    <Box sx={{ mb: 4 }}>
                      {item.data.map((url, pdfIndex) => (
                        <Box
                          key={pdfIndex}
                          sx={{
                            mb: 4,
                            borderRadius: 2,
                            bgcolor: "#fff",
                            width: "100%",
                            // overflow: "hidden",
                          }}
                        >
                          {/* Error Message */}
                          {error && (
                            <Typography
                              color="error"
                              variant="body2"
                              sx={{ mb: 2 }}
                            >
                              Error: {error.message}
                            </Typography>
                          )}
                          {/* PDF Document */}
                          <Box
                            sx={{
                              maxHeight: "auto",
                              width: "100% !important",
                            }}
                          >
                            <Document
                              className="stypdf"
                              file={url}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={setError}
                              loading={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    py: 4,
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              }
                              sx={{
                                width: "100% !important",
                                height: "auto",
                              }}
                            >
                              {Array.from(
                                new Array(numPages),
                                (el, pageIndex) => (
                                  <Box
                                    key={`page_${pageIndex + 1}`}
                                    sx={{ mb: 2 }}
                                  >
                                    <Page
                                      pageNumber={pageIndex + 1}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      scale={0.8} // Control zoom level, adjust the scale as needed
                                      width={
                                        document.querySelector("body")
                                          .offsetWidth - 0
                                      } // Adjust to 100% width of the container minus padding
                                    />
                                  </Box>
                                )
                              )}
                            </Document>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              case "gallery":
                return (
                  <Box key={index} sx={{ width: "100%", mt: "20px" }}>
                    {item.data.length === 1 ? (
                      <img
                        src={item.data[0]}
                        alt="Gallery"
                        onClick={() => handleClickOpen(item.data[0])}
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    ) : (
                      <ImageGrid>
                        {item.data.map((url, i) => {
                          // Define grid column spans based on the number of images and the index
                          let gridColumn = "span 4"; // Default span of 4 for three images in a row
                          if (item.data.length === 2) {
                            gridColumn = "span 6"; // Two images, each spans half the width
                          } else if (item.data.length === 4 && i === 3) {
                            gridColumn = "span 12"; // Fourth image spans full width
                          }

                          return (
                            <Box key={i} sx={{ gridColumn }}>
                              <img
                                src={url}
                                alt="Gallery"
                                onClick={() => handleClickOpen(url)}
                              />
                            </Box>
                          );
                        })}
                      </ImageGrid>
                    )}
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogContent>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                );
              case "singleimage":
                return (
                  <Box key={index} sx={{ width: "100%", mt: "20px" }}>
                    <img
                      src={item.data}
                      alt="Gallery"
                      onClick={() => handleClickOpen(item.data)}
                      style={{
                        width: "100%",
                        height: "auto",
                        // maxWidth: "1200px",
                      }}
                    />
                    <Dialog open={open} onClose={handleClose} maxWidth="lg">
                      <DialogContent>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ width: "100%", height: "auto" }}
                        />
                      </DialogContent>
                    </Dialog>
                  </Box>
                );

              case "videoUpload":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <video
                      controls
                      preload="auto"
                      style={{
                        width: "100%",
                        height: "700px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      }}
                      onError={(e) => {
                        console.error("Error loading video:", e);
                        console.error("Video source error:", e.nativeEvent);
                      }}
                    >
                      <source src={item.data[0]} type="video/mp4" />
                      <source src={item.data[0]} type="video/webm" />
                      <source src={item.data[0]} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                );
              case "videoUrl":
                return (
                  <Box key={index}>
                    <Button
                      href={item.videoUrl}
                      target="_blank"
                      variant="contained"
                      // startIcon={<AssignmentIcon sx={{ fontSize: "18px" }} />}
                      sx={{
                        borderRadius: "20px",
                        color: "#fcfafad6!important",
                        backgroundColor: "#faac18",
                        fontSize: "18px",
                        marginTop: "20px",
                      }}
                    >
                      {item.data}{" "}
                      <ArrowForwardIcon
                        sx={{ color: "#fcfafad6!important", mx: "3px" }}
                      />
                    </Button>
                  </Box>
                );
              case "youtubeUrl":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <iframe
                      width="100%"
                      height="920px"
                      src={`https://www.youtube.com/embed/${
                        item.youtubeUrl.split("v=")[1]
                      }`}
                      title="YouTube Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Box>
                );

              case "vimeoUrl":
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      height: "auto",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <iframe
                      src={`https://player.vimeo.com/video/${item.vimeoUrl
                        .split("/")
                        .pop()}`}
                      width="100%"
                      height="920px"
                      title="Vimeo Video"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Box>
                );
              default:
                return null;
            }
          })
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={projectRedierct}
          sx={{ marginTop: 3, width: "20%", backgroundColor: "#e0e0e0" }}
        >
          Create Project
        </Button>
        <Box
          sx={{ display: "flex", justifyContent: "left", marginTop: "20px" }}
        >
          {/* <div>
      {assessmentEnabledData.length > 0 ? (
        assessmentEnabledData.map((check) => (
          <KnowledgeCheckButton key={check.id} onClick={() => handleKnowledgeCheckClick(check.id)}>
         
            Knowledge Check
          </KnowledgeCheckButton>
        ))
      ) : (
        <p>No active knowledge checks available.</p>
      )}
    </div> */}
          <div>
            {assessmentEnabledData.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {assessmentEnabledData.map((check) => (
                  <KnowledgeCheckButton
                    key={check.id}
                    onClick={() =>
                      handleKnowledgeCheckClick(check.assessment_id)
                    }
                    style={{ marginBottom: "10px" }} // Optional: Adds space between buttons
                  >
                    {/* {check.assessment_id} */}
                    Knowledge Check
                  </KnowledgeCheckButton>
                ))}
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </Box>
      </Container>
      {/* fullscreen */}
      <Container
        maxWidth="xl"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box>
          <Button
            onClick={toggleFullScreen}
            variant="contained"
            sx={{
              position: "fixed",
              bottom: "15px",
              right: "20px",
              backgroundColor: "#00afd8",
              color: "#fff",
              width: "45px",
              height: "45px",
              minWidth: "auto",
              padding: 0,
              "&:hover": {
                backgroundColor: "#008bb3",
              },
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "& .MuiButton-label": {
                color: "transparent",
              },
            }}
            startIcon={
              isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />
            }
          />
        </Box>
      </Container>
    </>
  );
}

export default DisplayUnit;
