import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Switch,
  Grid,
  TextField,
  Checkbox,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import ReactQuill from "react-quill";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import { useLocation } from "react-router-dom";
import { LightbulbCircle } from "@mui/icons-material";
const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [assessment_name, setAssessment_name] = useState("");
  const [expiretimee, setExpiretime] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [statusToggle, setStatusToggle] = useState("0");
  const [assessmentId, setAssessmentId] = useState("");
  const [loading, setLoading] = useState(false); // New loading state
  const [status, setStatus] = useState(null); // null until fetched
  const [total, setTotal] = useState("");
  const {
    unitId,
    grade_id,
    grade_namee,
    grade_division_id,
    coursenamee,
    chapterindexx,
    chaptername,
    unitnamee,
  } = location.state || {};
  const token = localStorage.getItem("token_key");
  useEffect(() => {
    fetchAssessmentQuestions();
  }, [statusToggle]);

  const fetchAssessmentQuestions = async () => {
    try {
      const assessmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentname/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Extracting the data from the response
      // const { status, message, data } = assessmentResponse.data;
      const { message, data } = assessmentResponse.data;

      // Check if data is not empty
      if (data.length > 0) {
        const assessmentDetails = data[0]; // Accessing the first element in the data array
        console.log("Assessment_deatailss:", assessmentDetails);

        // Now you can access the properties directly
        const assessmentTitle = assessmentDetails.assessment_title;
        const totalScore = assessmentDetails.total_score;
        setTotal(totalScore);
        console.log("Assessment Title:", assessmentTitle);
        console.log("Total Score:", totalScore);
        setAssessment_name(assessmentTitle);
        const hours = Math.floor(assessmentDetails.expire_time / 3600);
        const minutes = Math.floor((assessmentDetails.expire_time % 3600) / 60);
        const seconds = assessmentDetails.expire_time % 60;
        const setTime = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
        setExpiretime(setTime);
        setStatusToggle(assessmentDetails.status);

        console.log("assessment status:", assessmentDetails.status);
        setAssessmentId(assessmentDetails._id);
      } else {
        console.log("No assessment data found.");
      }

      console.log("Unit id:", id);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/byunitId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const assessmentData = response.data.data || [];
      setAssessmentQuestions(
        Array.isArray(assessmentData) ? assessmentData : []
      );
    } catch (error) {
      console.error("Error fetching assessment questions:", error);
    }
  };

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleSubmit = async () => {
    const formattedAnswers = assessmentQuestions.map((question, index) => ({
      [index]: selectedAnswers[index] || "", // Only the selected answer or an empty string
    }));

    let totalPoints = 0;

    assessmentQuestions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];

      // Calculate points only for "yes/no" and "mcq" types
      if (
        question.question_type === "mcq" ||
        question.question_type === "yes/no"
      ) {
        if (selectedAnswer === question.answer) {
          totalPoints += question.points; // Add points if the answer is correct
        }
      }
      // For "descriptive" questions, points are always 0, so no action needed.
    });

    console.log("Formatted Answers:", formattedAnswers);
    console.log("Total Points:", totalPoints);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        // Check if assessmentId and unitId are available
        if (!assessmentId || !unitId) {
          console.log("Missing unitId or assessmentId, skipping API call.");
          return;
        }

        console.log("Assessment ID being sent:", assessmentId);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/get-enabled-status/${unitId}`,
          {
            params: { assessmentId: assessmentId }, // Pass assessmentId in the query parameters
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setStatus(response.data.status);
      } catch (error) {
        console.error("Error fetching status:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [unitId, assessmentId]); // Added assessmentId to dependency array

  const toggleStatus = async () => {
    try {
      const newStatus = status === 1 ? 0 : 1; // Toggle between 0 and 1
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/assessment/update-enabled-status/${unitId}`,
        {
          status: newStatus,
          assessmentId: assessmentId,
          grade_division_id: grade_division_id,
          grade_idf: grade_id, // Pass assessmentId in the body
        },
        {
          headers: { Authorization: `Bearer ${token}` }, // Authorization header with Bearer token
        }
      );

      setStatus(newStatus); // Update local status
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  if (loading) return <p>Loading...</p>;

  return (
    <Container maxWidth="100%" sx={{ paddingRight: "0px" }}>
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
        mb={2}
        mt={2}
      >
        <Typography variant="h4" component="h1" p={1}>
          Quiz View
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e53935", // Red background
          color: "#ffffff", // White text
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 20px",
          borderRadius: "8px",
          width: "100%",
          margin: "20px auto", // Centers the header
        }}
      >
        <LightbulbCircle
          sx={{
            marginRight: "10px",
            fontSize: { xs: "18px", sm: "20px", md: "120px", lg: "120px" },
          }}
        />
        <Typography
          variant="h6"
          component="span"
          sx={{ fontSize: { xs: "18px", sm: "20px", md: "50px", lg: "50px" } }}
        >
          Knowledge Check
        </Typography>
      </Box>

      <Box
        mt={2}
        sx={{
          padding: "20px",
          backgroundColor: "#fbe9e7",
          borderRadius: "60px",
          border: "1px solid",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Container maxWidth="95%" sx={{ padding: "24px !important" }}>
          {Array.isArray(assessmentQuestions) &&
          assessmentQuestions.length > 0 ? (
            assessmentQuestions.map((question, index) => (
              <Box key={index} sx={{ marginBottom: "20px" }}>
                {/* Question Header */}
                <Grid
                  container
                  alignItems="center"
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: "10px", sm: "0" },
                  }}
                >
                  {/* Question Number */}
                  <Grid item xs={12} sm={2} md={1}>
                    <Box
                      sx={{
                        backgroundColor: "#fb8c00",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "22px",
                          lg: "28px",
                        },
                        borderRadius: "50%",
                        height: { xs: "50px", sm: "60px" },
                        width: { xs: "50px", sm: "60px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mx: "auto", // Centers the box horizontally on small screens
                      }}
                    >
                      {index + 1}
                    </Box>
                  </Grid>

                  {/* Question Text */}
                  <Grid item xs={12} sm={10} md={11}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: {
                          xs: "16px !important",
                          sm: "18px !important",
                          md: "22px !important",
                          lg: "30px !important",
                        },
                        color: "#000 !important",
                        textAlign: { xs: "center", sm: "left" },
                      }}
                    >
                      {question.question_text}
                    </Typography>
                  </Grid>

                  {/* Question Image */}
                  {question?.question_image && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: { xs: "center", md: "center", lg: "left" }, // Responsive alignment
                        my: 4,
                      }}
                    >
                      <img
                        src={question?.question_image}
                        alt="Question"
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                {/* Question Type Handling */}
                <Grid
                  container
                  // justifyContent="center"
                  flex= "start"
                  sx={{ marginTop: "10px" }}
                >
                  <Grid item xs={10}>
                    {question.question_type === "mcq" && (
                      <Grid container spacing={2} mt={2} ml={5}>
                        {question.options.map((option, optionIndex) => (
                          <Grid item xs={12} md={6} key={optionIndex}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                checked={
                                  // selectedAnswers[index] === question.answer
                                  option === question.answer
                                }
                                // onChange={(e) =>
                                //   handleAnswerChange(index, option)
                                // }
                                sx={{
                                  transform: "scale(1.5)",
                                  "&.Mui-checked": {
                                    color: "#43a047",
                                  },
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: {
                                    xs: "18px",
                                    sm: "20px",
                                    md: "22px",
                                    lg: "24px",
                                  },
                                  fontWeight: 400,
                                  color:
                                    question.answer === option
                                      ? "#43a047"
                                      : "#000",
                                }}
                              >
                                {option}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    <Grid item ml={9} mt={2} xs={12} display="flex" flex='start'>
                      {question.question_type === "yes/no" && (
                        <RadioGroup
                          name={`question-${index}`}
                          value={question.answer || ""}
                          // onChange={(e) =>
                          //   handleAnswerChange(index, e.target.value)
                          // }
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio sx={{ transform: "scale(1.5)", color: "#aca09f !important" }} />}
                            label={
                              <span
                                style={{
                                  color:
                                    question.answer === "Yes"
                                      ? "green"
                                      : "inherit",
                                  fontWeight: 400,
                                }}
                              >
                                Yes
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio sx={{ transform: "scale(1.5)" }} />}
                            label={
                              <span
                                style={{
                                  color:
                                    question.answer === "No"
                                      ? "green"
                                      : "inherit",
                                }}
                              >
                                No
                              </span>
                            }
                          />
                        </RadioGroup>
                      )}
                    </Grid>

                    {question.question_type === "descriptive" && (
                      <textarea
                        style={{
                          width: "100%",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          fontSize: "18px",
                        }}
                        value={question.answer || ""}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                {/* mark: {question.points} */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" }, // Center on small screens
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "60px", sm: "80px" }, // Adjust size for smaller screens
                      height: { xs: "60px", sm: "80px" },
                      overflow: "hidden", // Ensures rounded corners apply to the child boxes
                      display: "flex",
                      flexDirection: "column", // Stacks the two halves vertically
                    }}
                  >
                    {/* Top Half - Orange */}
                    {/* <Box
                      sx={{
                        backgroundColor: "#fb8c00",
                        color: "#fff",
                        height: "50%", // Takes half the parent's height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "16px",
                          lg: "18px",
                        },
                      }}
                    >
                      {question.question_type === "descriptive" ? (
                        <input
                          type="number"
                          value={
                            // marks[question._id] ||
                            // getEvaluatedAnswer(question._id)?.points ||
                            // 0
                            question.points
                          }
                          min="0"
                          max={question.points}
                          style={{
                            width: "40px",
                            textAlign: "center",
                            fontSize: "16px",
                            border: "none",
                            background: "transparent",
                            color: "#fff",
                            fontWeight: "bold",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Box> */}

                    {/* Bottom Half - Black */}
                    <Box
                      sx={{
                        backgroundColor: "#000",
                        borderRadius: "6px",
                        color: "#fff",
                        height: "50%", // Takes half the parent's height
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {question.points}
                    </Box>
                  </Box>
                </Grid>
                {/* Divider */}
                {index < assessmentQuestions.length - 1 && (
                  <Divider sx={{ margin: "20px 0", borderColor: "#000" }} />
                )}
              </Box>
            ))
          ) : (
            <Typography>No questions available.</Typography>
          )}
          {/* Submit Button */}
          {/* <Box sx={{ mt: 3, textAlign: "center" }}>
            <p>Total Marks: {total}</p> */}
            
            {/* <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit Answers
            </Button> */}
          {/* </Box> */}
          <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
          // marginRight: "161px",
        }}
      >
        <Typography variant="h6" sx={{ marginRight: "16px" }}>
          Total Score
        </Typography>
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "6px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          

          {/* Bottom Half - Black */}
          <Box
            sx={{
              backgroundColor: "#000",
              borderRadius: "6px",
              color: "#fff",
              height: "50%", // Takes half the parent's height
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {total}
          </Box>
        </Box>
      </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Assessment;
