import "./App.css";
import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// import HomeSchool from "./components/pages/school/Home";
import Students from "./components/pages/school/StudentPage";
import Teachers from "./components/pages/school/TeachersListPage";
import Parents from "./components/pages/school/ParentsTable";
import Login from "./components/Login";
import Register from "./components/Signup";
import Home from "./components/Home";
import Admin from "./components/pages/Admin";
import Student from "./components/pages/Student";
import Teacher from "./components/pages/teacher/Teacher";
import RolePermission from "./components/pages/teacher/RolePermission";
import Layout from "./components/Layout";
import StudentDas from "./components/pages/Student";

import AddCoursePage from "./components/pages/school/AddCoursePage";
import AddClassForm from "./components/pages/school/AddClassForm";
import ClassList from "./components/pages/school/GradeDivision";
import ProjectSubmission from "./components/pages/student/ProjectSubmission";
import StudentDetails from "./components/pages/student/StudentAssignmentUpload";
import ProtectedSchoolRoute from "./components/route_protection/ProtectedAdminRoute";
import ProtectedTeacherRoute from "./components/route_protection/ProtectedTeacherRoute";
import ProtectedStudentRoute from "./components/route_protection/ProtectedStudentRoute";
import ProtectedParentRoute from "./components/route_protection/ProtectedParentRoute";
import Chapters from "./components/pages/school/Chapters";
import AddStudentPage from "./components/pages/school/AddStudentPage";
import EditStudentPage from "./components/pages/school/EditStudentPage";
import AddTeacher from "./components/pages/school/AddTeacher";
import EditTeacherPage from "./components/pages/school/EditTeacherPage";
import ResourcePersons from "./components/pages/school/ResourcePersonListPage"
import AddResourcePerson from "./components/pages/school/AddResourcePerson"
import EditResourcePerson from "./components/pages/school/EditResourcePersonPage"

import EditParentPage from "./components/pages/school/EditParentPage";
import ChaptersTable from "./components/pages/school/ChaptersTable";
import Dashboard from "./components/Dashboard";
import SchoolPage from "./components/pages/school/SchoolPage";
import UploadContent from "./components/pages/UploadContent";
import McqAssesment from "./components/pages/unit/McqAssesment"


// import CompanyPage from './pages/school/CompanyPage';
// import Questions from './pages/questions/QuestionsPage';
// import QuestionsForm from './pages/questions/QuestionsForm';

import AssessmentForm from "./components/pages/questions/AssessmentForm";
import AssessmentsList from "./components/pages/questions/AssessmentList";
import QuestionsList from "./components/pages/questions/QuestionsList";
// import UpdateQuestion from "./components/pages/questions/UpdateQuestion";
import AddQuestionForm from "./components/pages/questions/AddQuestionForm";
import UpdateQuestionForm from "./components/pages/questions/UpdateQuestion";
import UpdateAssessmentPage from "./components/pages/questions/UpdateAssessmentPage";

import EditorComponent from "./components/pages/quill/EditorComponent"


// import AssessmentForm from "./components/pages/questions/AssessmentForm";
// import AssessmentsList from "./components/pages/questions/AssessmentList";
import CoursesTable from "./components/pages/school/course-list";
import EditCourse from "./components/pages/school/CourseEditPage";
// import ChapterList from "./components/pages/school/ChapterList";
import EditChapter from "./components/pages/school/EditChapter";
// import ProjectPage from "./components/pages/school/ProjectPage";
import ProjectView from "./components/pages/school/ProjectView";
// import ProjectForm from "./components/pages/school/ProjectForm";
import Grade from "./components/pages/school/Grade";
import GradeView from "./components/pages/school/GradeView";
import GradesAssigned from "./components/pages/school/GradesAssigned";
import UnitAssessment from "./components/pages/school/UAssessment";
import ProductAssigned from "./components/pages/school/ProductAssigned";
// import TeacherComment from "./components/pages/school/TeachersComment";
import DepartmentList from "./components/pages/school/DepartmentList"
import EditDepartment from "./components/pages/school/EditDepartment"
import AddDepartment from "./components/pages/school/AddDepartment";
import AssignedDepartmentList from "./components/pages/school/AssignedDepartmentList"
import EditAssignedDepartment from "./components/pages/school/EditAssignedDepartment"
import AddAssignedDepartment from "./components/pages/school/AddAssignedDepartment"
import AssignCourse from "./components/pages/school/AssignCourse";
import AssignedCoursesList from "./components/pages/school/AssignedCoursesList"
import DisplayUnit from "./components/pages/unit/DisplayUnit"
import EditAssignedCourse from "./components/pages/school/EditAssignedCourse"
import AssignProduct from "./components/pages/school/AssignProduct";
import AssignProductList from "./components/pages/school/AssignProductList";

// 
import OngoingCourses from "./components/pages/student/OngoingCourses";
import OngoingCoursesChapters from "./components/pages/student/OngoingCoursesChapters";
import TeachersOngoingCoursesChapters from "./components/pages/teacher/OngoingCoursesChapters";

import ProjectPage from "./components/pages/student/ProjectPage";
import ProjectForm from "./components/pages/student/ProjectForm";
import TeacherComment from "./components/pages/student/TeachersComment";
import StudentDashboard from "./components/pages/student/Dashboard";
import Dp from "./components/pages/unit/DispUnit"
import ProjectInterface from './components/pages/project/ProjectUpload'
import ProjectUploads from './components/pages/project/ProjectUploads'
import ProjectInterfaceView from './components/pages/project/DisplayProject'
import ProjectEdit from './components/pages/project/EditProject'
 
import RolesList from "./components/pages/teacher/RoleListing"

import StudentList from "./components/pages/teacher/StudentList";
import Comments from "./components/pages/teacher/comments";
import ProjectPage_teacher from "./components/pages/teacher/ProjectPage";
import Dashboard_teacher from "./components/pages/teacher/Dashboard";

// Assessment
import UnitAssessment_student from "./components/pages/student/UnitAssessmentView";
import DisplayUnitstudent from "./components/pages/student/DisplayUnit_student"
import TeacherCourses from "./components/pages/teacher/TeacherCourses";
import AssignDepartment from "./components/pages/school/AssignDepartment";
 
import DisplayUnitteacher from "./components/pages/teacher/DisplayUnit_teacher";
import UnitAssessmentList from "./components/pages/student/UnitAssessmentList";
import UnitAssessmentList1 from "./components/pages/student/UnitAssessmentCourseList";
import UnitAssessmentList2 from "./components/pages/student/UnitAssessmentChapterList";
import UnitAssessment1 from "./components/pages/student/UnitAssessment";
import AssessmentSubmitted from "./components/pages/student/AssessmentSubmitted";
import AssesmentPaper from "./components/pages/teacher/Assesmentpaper"
import AssessmentReport from "./components/pages/teacher/AssessmentReport";
import AssessmentView from "./components/pages/teacher/AssessmentView";
import AssessmentAnswerView from "./components/pages/student/AssessmentAnswerView"
import ResetPassword from "./components/RestPassword"
import TeacherDashboard from "./components/pages/Teacher";
import AddQuiz from "./components/pages/teacher/AddQuiz";
import QuizList from "./components/pages/teacher/QuizList";

 

// import TeacherComment_school from "./components/pages/school/TeachersComment";

function App() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   Axios.defaults.withCredentials = true;
  //   Axios.get(`${process.env.REACT_APP_BACKEND}/auth/verify`)
  //     .then((res) => {
  //       if (res.data.status) {
  //         console.log(res.data);
  //       } else {
  //         navigate("/");
  //       }
  //     })
  //     .catch((err) => {
  //       // Handle network or server errors
  //       console.error("Error during verification:", err.message || err);
  //       navigate("/"); // Optionally navigate back to login on error
  //     });
  // }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
      <Route element={<Layout />}>
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/dp" element={<Dp />} />
        <Route path="/projectupload/:id" element={<ProjectInterface />} />
        <Route path="/projectuploads/:id" element={<ProjectUploads />} />
        <Route path="/stu_dashboard" element={<StudentDas />} />
        <Route path="/unitinterface/:id" element={<DisplayUnit />} />
        <Route path="/projectinterface/:id" element={<ProjectInterfaceView />} />
        <Route path="/chapters" element={<Chapters />} />
        <Route path="/quill" element={<EditorComponent />} />
        {/* <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/" element={<HomeSchool />} /> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/students" element={<Students />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/add-student" element={<AddStudentPage />} />
        <Route path="/edit-student/:id" element={<EditStudentPage />} />
        <Route path="/add-teacher" element={<AddTeacher />} />
        <Route path="/edit-teacher/:id" element={<EditTeacherPage />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/parents" element={<Parents />} />
        <Route path="/edit-parent/:id" element={<EditParentPage />} />
        <Route path="/project-sub" element={<ProjectSubmission />} />
        <Route path="/assignment_sub" element={<StudentDetails />} />
        {/* <Route path="/class" element={<AddClassForm />} /> */}
        {/* <Route path="/class" element={<ClassList />} /> */}
        <Route path="/course" element={<AddCoursePage />} />
        <Route path="/chapters" element={<Chapters />} />
        <Route path="/mcqassesment" element={<McqAssesment />} />

        <Route path="/class/table" element={<ChaptersTable />} />
        <Route path="/rolepermission" element={<RolePermission />} />
        <Route path="/role" element={<RolesList />} />

        <Route path="/chapterslist" element={<ChaptersTable />} />

        <Route path="/edit_chapter/:id" element={<EditChapter />} />

        {/* <Route path="/coursesTable" element={<CoursesTable />} /> */}

        <Route path="/edit_course/:id" element={<EditCourse />} />
        <Route path="/CoursesTable" element={<CoursesTable />} />

        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route path="/schoolpage" element={<SchoolPage />} />
        <Route path="/assessment" element={<AssessmentForm />} />
        <Route path="/list" element={<AssessmentsList />} />
        <Route path="/uploadcontent" element={<UploadContent />} />
        <Route path="/assessmentlist" element={<AssessmentsList />} />
        {/* <Route path="/questionslist" element={<QuestionsList/>} /> */}
        <Route path="/questions/:assessmentId" element={<QuestionsList />} />
        <Route path="/questions/add/:assessmentId" element={<AddQuestionForm />} />
        {/* <Route path="/questions" element={<QuestionsList />} /> */}
        <Route path="/projectpage" element={<ProjectPage />} />
        <Route path="/projectview/:id" element={<ProjectView />} />
        <Route path="/projectform" element={<ProjectForm />} />
        <Route path="/grade" element={<Grade />} />
        <Route path="/gradeview" element={<GradeView />} />
        {/* <Route path="/gradeassigned" element={<GradesAssigned />} /> */}
        <Route path="/unitassesment" element={<UnitAssessment />} />
        <Route path="/productassigned" element={<ProductAssigned />} />
        {/* <Route path="/teachercomment" element={<TeacherComment />} /> */}
        <Route path="/assign-to-course" element={<AssignCourse />} />
        <Route path="/assigned-courses-list" element={AssignedCoursesList}></Route>
        {/* <Route path="/edit-assigned-course/:id" element={EditAssignedCourse}></Route> */}

        {/* <Route
          path="/questions/add/:assessmentId"
          element={<AddQuestionForm />}
        /> */}
        <Route path="/update-question/:id" element={<UpdateQuestionForm />} />
        <Route path="/update-assessment/:id" element={<UpdateAssessmentPage />} />
        <Route path="/rolepermission" element={<RolePermission />} />
        <Route path="teachers" element={<Teachers />} />
        <Route path="add-teacher" element={<AddTeacher />} />
        <Route path="add-student" element={<AddStudentPage />} />
        <Route path="edit-student/:id" element={<EditStudentPage />} />
        <Route path="edit-teacher/:id" element={<EditTeacherPage />} />
        <Route path="department-list" element={<DepartmentList />} />
        <Route path="/departments/edit/:id" element={<EditDepartment />} />
        <Route path="departments/add" element={<AddDepartment />} />
        <Route path="school/assigned/departments" element={<AssignedDepartmentList />} />
        <Route path="/assigned/departmentList/edit/:id" element={<EditAssignedDepartment />} />
        <Route path="/assigned/departmentList/add" element={<AddAssignedDepartment />} />



        {/* ----------------------------------------school routes---------------------------------------- */}
        <Route path="/school" element={
          <ProtectedSchoolRoute>
            <Dashboard />
          </ProtectedSchoolRoute>
        } />
        <Route path="school/departments" element={<ProtectedSchoolRoute><DepartmentList /></ProtectedSchoolRoute>} />
        <Route path="school/sview" element={<ProtectedSchoolRoute><Students /></ProtectedSchoolRoute>} />
        <Route path="school/parents" element={<ProtectedSchoolRoute><Parents /></ProtectedSchoolRoute>} />
        <Route path="school/teachers" element={<ProtectedSchoolRoute><Teachers /></ProtectedSchoolRoute>} />
        <Route path="school/classform" element={<ProtectedTeacherRoute><AddClassForm /></ProtectedTeacherRoute>} />
        <Route path="school/resourse-person" element={<ProtectedSchoolRoute><ResourcePersons /></ProtectedSchoolRoute>} />
        <Route path="school/add-resourse-person" element={<ProtectedSchoolRoute><AddResourcePerson /></ProtectedSchoolRoute>} />
        <Route path="school/edit-resourse-person" element={<ProtectedSchoolRoute><EditResourcePerson /></ProtectedSchoolRoute>} />
        <Route path="school/add-teacher" element={<ProtectedSchoolRoute><AddTeacher /></ProtectedSchoolRoute>} />
        <Route path="school/add-student" element={<ProtectedSchoolRoute><AddStudentPage /></ProtectedSchoolRoute>} />
        <Route path="school/edit-student/:id" element={<ProtectedSchoolRoute><EditStudentPage /></ProtectedSchoolRoute>} />
        <Route path="school/edit-teacher/:id" element={<ProtectedSchoolRoute><EditTeacherPage /></ProtectedSchoolRoute>} />
        <Route path="school/edit-parent/:id" element={<ProtectedSchoolRoute><EditParentPage /></ProtectedSchoolRoute>} />
        <Route path="school/departments/add" element={<ProtectedSchoolRoute><AddDepartment /></ProtectedSchoolRoute>} />
        <Route path="school/departments/edit/:id" element={<ProtectedSchoolRoute><EditDepartment /></ProtectedSchoolRoute>} />
        <Route path="school/department-list" element={<ProtectedSchoolRoute><DepartmentList /></ProtectedSchoolRoute>} />
        <Route path="school/assign-department/list" element={<ProtectedSchoolRoute><AssignedDepartmentList /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-department-list/edit/:id" element={<ProtectedSchoolRoute><EditAssignedDepartment /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-department-list/add" element={<ProtectedSchoolRoute><AddAssignedDepartment /></ProtectedSchoolRoute>} />
        <Route path="school/grade-division" element={<ProtectedSchoolRoute><ClassList /></ProtectedSchoolRoute>} />
        <Route path="school/chapters" element={<ProtectedSchoolRoute><Chapters /></ProtectedSchoolRoute>} />
        <Route path="school/unitassesment" element={<ProtectedSchoolRoute><UnitAssessment /></ProtectedSchoolRoute>} />
        <Route path="school/productassigned" element={<ProtectedSchoolRoute><ProductAssigned /></ProtectedSchoolRoute>} />
        <Route path="school/course-list" element={<ProtectedSchoolRoute><CoursesTable /></ProtectedSchoolRoute>} />
        {/* <Route path="school/gradeassigned" element={<ProtectedSchoolRoute><GradesAssigned /></ProtectedSchoolRoute>} /> */}
        <Route path="school/grade" element={<ProtectedSchoolRoute><Grade /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-course" element={<ProtectedSchoolRoute><AssignCourse /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-courses-list" element={<ProtectedSchoolRoute><AssignedCoursesList /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-courses-list/:id" element={<ProtectedSchoolRoute><EditAssignedCourse /></ProtectedSchoolRoute>} />
        <Route path="school/assign-product" element={<ProtectedSchoolRoute><AssignProduct /></ProtectedSchoolRoute>} />
        <Route path="school/assigned-product-list" element={<ProtectedSchoolRoute><AssignProductList /></ProtectedSchoolRoute>} />
        <Route path="school/assign-department" element={<ProtectedSchoolRoute><AssignDepartment /></ProtectedSchoolRoute>} />
       
        
        <Route path="school/course" element={<ProtectedSchoolRoute><AddCoursePage /></ProtectedSchoolRoute>} />





        {/* ----------------------------------------Teachers routes---------------------------------------- */}
        <Route
          path="/teacher"
          element={
            <ProtectedTeacherRoute>
              <TeacherDashboard />
            </ProtectedTeacherRoute>
          }
        />
        <Route path="teacher/sview" element={<ProtectedTeacherRoute> <Students /> </ProtectedTeacherRoute>} />
        {/* <Route path="teachers" element={<ProtectedTeacherRoute><Teachers /></ProtectedTeacherRoute>} /> */}
        <Route path="teacher/assessment" element={<ProtectedTeacherRoute>  <AssessmentForm /> </ProtectedTeacherRoute>} />
        {/* <Route path="teacher/list" element={<ProtectedTeacherRoute>  <AssessmentsList /> </ProtectedTeacherRoute>} /> */}
        <Route path="teacher/class" element={<ProtectedTeacherRoute>  <AddClassForm /> </ProtectedTeacherRoute>} />
        <Route path="teacher/course" element={<ProtectedTeacherRoute> <AddCoursePage /> </ProtectedTeacherRoute>} />
        <Route path="teacher/chapters" element={<ProtectedTeacherRoute> <Chapters /> </ProtectedTeacherRoute>} />
        {/* <Route path="teacher/gradeassigned" element={<ProtectedTeacherRoute><GradesAssigned /></ProtectedTeacherRoute>} /> */}
        <Route path="teacher/grade" element={<ProtectedTeacherRoute><Grade /></ProtectedTeacherRoute>} />
        <Route path="teacher/project-sub" element={<ProtectedTeacherRoute><ProjectSubmission /></ProtectedTeacherRoute>} />
        <Route path="teacher/my-courses" element={<ProtectedTeacherRoute><TeacherCourses /></ProtectedTeacherRoute>} />
        <Route path="teacher/unit-assessment-report" element={<ProtectedTeacherRoute><AssessmentReport /></ProtectedTeacherRoute>} />
        {/* Quiz */}
        <Route path="teacher/add-quiz" element={<ProtectedTeacherRoute><AddQuiz /></ProtectedTeacherRoute>} />
        <Route path="teacher/add-quiz-list" element={<ProtectedTeacherRoute><QuizList /></ProtectedTeacherRoute>} />
        

        {/* -----------------------------------------Students routes---------------------------------------- */}
        <Route
          path="/student"
          element={
            <ProtectedStudentRoute>
              <Student />
            </ProtectedStudentRoute>
          } />
        <Route path="student/project" element={<ProtectedStudentRoute><ProjectSubmission /></ProtectedStudentRoute>} />
        <Route path="student/assignment_sub" element={<ProtectedStudentRoute><StudentDetails /></ProtectedStudentRoute>} />
        <Route path="student/grade" element={<ProtectedStudentRoute><Grade /></ProtectedStudentRoute>} />
        <Route path="student/project-sub" element={<ProtectedStudentRoute><ProjectSubmission /></ProtectedStudentRoute>} />
        <Route path="student/gradeassigned" element={<ProtectedStudentRoute><GradesAssigned /></ProtectedStudentRoute>} />
        {/* athi */}
        <Route path="/student/ongoing_courses" element={<ProtectedStudentRoute><OngoingCourses /></ProtectedStudentRoute>} />
          <Route path="student/ongoing_courses_chapter/:id" element={<ProtectedStudentRoute><OngoingCoursesChapters /></ProtectedStudentRoute>} />
          <Route path="student/project-page" element={<ProtectedStudentRoute><ProjectPage /></ProtectedStudentRoute>} />
          <Route path="student/projectform" element={<ProtectedStudentRoute><ProjectForm /></ProtectedStudentRoute>} />
          <Route path="student/teachercomment" element={<ProtectedStudentRoute><TeacherComment /></ProtectedStudentRoute>} />
          <Route path="student/dashboard" element={<ProtectedStudentRoute><StudentDashboard /></ProtectedStudentRoute>} />
          <Route path="student/projectinterface" element={<ProtectedStudentRoute><ProjectInterface /></ProtectedStudentRoute>} />
          <Route path="student/projectinterface" element={<ProtectedStudentRoute><ProjectInterface /></ProtectedStudentRoute>} />

          <Route path="student/unit-interface/:id" element={<ProtectedStudentRoute><DisplayUnitstudent /></ProtectedStudentRoute>} />
          <Route path="student/editproject/:id" element={<ProtectedStudentRoute><ProjectEdit /></ProtectedStudentRoute>} />
          {/* Assessment */}
          {/* <Route path="student/unit-assessment/:id" element={<ProtectedStudentRoute><UnitAssessment_student /></ProtectedStudentRoute>} /> */}
          <Route path="student/unit-assessment-course-list/course/:id/unit/:unitid" element={<ProtectedStudentRoute><UnitAssessment_student /></ProtectedStudentRoute>} />

          {/* student/unit-assessment-course-list/course/:id */}
          <Route path="student/unit-assessment/:id" element={<ProtectedStudentRoute><UnitAssessment1 /></ProtectedStudentRoute>} />
          <Route path="student/unit-assessment-course-list" element={<ProtectedStudentRoute><UnitAssessmentList1 /></ProtectedStudentRoute>} />
          <Route path="student/unit-assessment-course-list/course/:id" element={<ProtectedStudentRoute><UnitAssessmentList2 /></ProtectedStudentRoute>} />
          <Route path="student/unit-assessment-List" element={<ProtectedStudentRoute><UnitAssessmentList /></ProtectedStudentRoute>} />
          {/* AssessmentSubmitted */}
          <Route path="student/unit-assessment/submitted" element={<ProtectedStudentRoute><AssessmentSubmitted /></ProtectedStudentRoute>} />
          <Route path="student/unit-assessment-List/:unitid/:id" element={<ProtectedStudentRoute><AssessmentAnswerView/> </ProtectedStudentRoute>} />

          {/* athi */}
          <Route path="teacher/student-list" element={<ProtectedTeacherRoute><StudentList /></ProtectedTeacherRoute>} /> 
            <Route path="teacher/comments/:id" element={<ProtectedTeacherRoute><Comments /></ProtectedTeacherRoute>} />
            <Route path="teacher/ProjectPage" element={<ProtectedTeacherRoute><ProjectPage_teacher /></ProtectedTeacherRoute>} />
            <Route path="teacher/ongoing_courses_chapter/:id" element={<ProtectedTeacherRoute><TeachersOngoingCoursesChapters /></ProtectedTeacherRoute>} />
            
            <Route path="teacher/unit-interface/:id" element={<ProtectedTeacherRoute><DisplayUnitteacher /></ProtectedTeacherRoute>} />
           
            <Route path="teacher/assessment/:id" element={<ProtectedTeacherRoute><AssesmentPaper /></ProtectedTeacherRoute>} />
            <Route path="teacher/assessment-view/:unitid/:id" element={<ProtectedTeacherRoute><AssessmentView /></ProtectedTeacherRoute>} />
 
        {/* -----------------------------------------Parents routes---------------------------------------- */}
        <Route
          path="/parent"
          element={
            <ProtectedParentRoute>
              <Dashboard />
            </ProtectedParentRoute>
          }
        />
        <Route
          path="parent/sview"
          element={
            <ProtectedParentRoute>
              <Students />
            </ProtectedParentRoute>
          }
        />
        {/* <Route path="parent/project-sub" element={<ProtectedStudentRoute><ProjectSubmission /></ProtectedStudentRoute>} />
        <Route path="parent/assignment_sub" element={<ProtectedStudentRoute><StudentDetails /></ProtectedStudentRoute>} /> */}
      </Route>
    </Routes>
  );
}

export default App;
