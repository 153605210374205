import React, { useEffect, useState } from 'react';
import { TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl, } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../../utils/AuthUtils';

const EditTeacherPage = () => {
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [form, setForm] = useState({
    teacher_id: '',
    name: '',
    qualification: '',
    mobile: '',
    email: '',
    hire_Date: '',
    subject_specialization: "",
    teacherCategory: "",
    teacherSubCategory: "",
    departmentId: ""
  });
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [departments, setDepartments] = useState([]);

  const [qualifications] = useState([
    "Diploma in Elementary Education (D.El.Ed)",
    "Bachelor of Elementary Education (B.El.Ed)",
    "Bachelor of Education (B.Ed)",
    "Master of Education (M.Ed)",
    "Teacher Eligibility Test (TET)",
    "Central Teacher Eligibility Test (CTET)",
    "State Eligibility Test (SET)",
    "National Eligibility Test (NET)",
    "Ph.D.",
    "Diploma in Education (D.Ed)",
    "Postgraduate Diploma in Education (PGDE)",
    "Master of Philosophy (M.Phil) in Education",
    "Certificate in Guidance (CIG)",
    "Special Education Certificate",
    "Bachelor of Physical Education (B.P.Ed)",
    "Master of Physical Education (M.P.Ed)"
  ]);

  useEffect(() => {
    fetchTeacher(id);
  }, [id]);

  const fetchTeacher = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      const data = await response.json();
      if (data?.data) {
        setForm({
          teacher_id: data.data.teacher_id || '',
          teacherCategory: data.data.teacher_categoryId._id || '',
          teacherSubCategory: data.data.teacher_subCategoryId._id || '',
          // departmentId: data.data.departmentId.department_name || '',
          departmentId: data.data.departmentId._id || '',
          name: data.data.name || '',
          qualification: data.data.qualification || '',
          profile_url: data.data.profile_url || "",
          mobile: data.data.mobile || '',
          email: data.data.email || '',
          hire_Date: data.data.hire_Date ? new Date(data.data.hire_Date).toISOString().split('T')[0] : '',
        });
        if (data.data.profile_url) {
          setProfileImagePreview(data.data.profile_url); // Set the preview from the stored URL
        }
        console.log("form values: ", form);
        
      } else {
        setError('Teacher not found');
      }
    } catch (err) {
      setError('Failed to fetch teacher');
    } finally {
      setLoading(false);
    }
  };
  

  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);

  // Fetch teacher subcategories based on selected category
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!form.teacherCategory) return; // Skip if no category is selected
      console.log(form.teacherCategory);
      
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teacher/sub/${form.teacherCategory}`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setTeacherSubCategories(response.data.data);
        // Filter subcategories based on the selected category
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
        setError("Failed to fetch teacher sub-categories");
      }
    };
    fetchTeacherSubCategories();
  }, [form.teacherCategory]); // Depend on teacherCategory

   // Update filtered subcategories when subcategories data changes
   useEffect(() => {
    if (form.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(sub => sub.teacher_category_id === form.teacherCategory)
      );
    }
  }, [teacherSubCategories, form.teacherCategory]);

  // Fetch teacher categories
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchDepartments();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });

    if (name === "teacherCategory") {
      // Clear teacher subcategory when teacher category changes
      setForm({
        ...form,
        teacherSubCategory: "" // Reset the subcategory
      });
      setFilteredSubcategories([]); // Clear the filtered subcategories list
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = getToken();
      if(!token){
        setError("Please login to proceed");
      }

      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        if (form[key]) formData.append(key, form[key]);
      });

      // If a new image is selected, append the profileImage; otherwise, append the existing profile_url
      if (profileImage) {
        formData.append("profileImage", profileImage); // Append the image only if selected
      } else {
        formData.append("profileImage", form.profile_url); // Retain the current image if no new one is uploaded
      }

      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`,
        formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status===200) {
        setSuccess(true);
        setForm({
          teacher_id: '',
          name: '',
          qualification: '',
          mobile: '',
          email: '',
          hire_Date: '',
          subject_specialization: "",
          teacherCategory: "",
          teacherSubCategory: "",
          departmentId: ""
        })
        setTimeout(() => navigate('/teachers'), 1000); // Redirect after 2 seconds
      } else {
        setError('Failed to update teacher');
      }
    } catch (err) {
      setError('Failed to update teacher');
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(null);
  };

  const handleCancel = () => {
    navigate('/teachers')
  }

  // if (loading) {
  //   return <CircularProgress />;
  // }
  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4">
        Edit Teacher
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" variant="outlined" required sx={{width:"100%"}}>
              <InputLabel>Teacher Category</InputLabel>
              <Select
                value={form.teacherCategory}
                onChange={(e) => setForm({ ...form, teacherCategory: e.target.value, teacherSubCategory: "" })}
                
                // setFilteredSubcategories([]);
                label="Teacher Category"
                aria-required="true"
                name="teacherCategory" 
              >
                {teacherCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.teacher_name}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" variant="outlined" required sx={{width:"100%"}}>
                <InputLabel>Teacher Sub Category</InputLabel>
                <Select
                  value={form.teacherSubCategory}
                  onChange={(e) => setForm({ ...form, teacherSubCategory: e.target.value })}
                  label="Teacher Sub Category"
                  aria-required="true"
                  disabled={!form.teacherCategory}  // Disable if no category is selected
                >
                  {filteredSubcategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.teacher_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* departments */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required sx={{width:"100%"}}>
                <InputLabel>Department</InputLabel>
                <Select
                  value={form.departmentId}
                  onChange={(e) => setForm({ ...form, departmentId: e.target.value })}
                  label="Department"
                  aria-required="true"
                >
                  {departments.map((department) => (
                    // <MenuItem key={index} value={department._id}>
                    <MenuItem key={department._id} value={department.department_id}>
                      {department.department_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Qualification</InputLabel>
                <Select
                  value={form.qualification}
                  onChange={(e) => setForm({ ...form, qualification: e.target.value })}
                  label="Qualification"
                  aria-required="true"
                >
                  {qualifications.map((qualification, index) => (
                    <MenuItem key={index} value={qualification}>
                      {qualification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile"
                type="text"
                value={form.mobile}
                onChange={(e) => setForm({ ...form, mobile: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                inputProps={{ pattern: "[0-9]{10}" }}
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Hire Date"
                type="date"
                value={form.hire_Date}
                onChange={(e) => setForm({ ...form, hire_Date: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
                aria-required="true"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Profile Image
              </Typography>
              <Button variant="contained" component="label">
                Upload Profile Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>

              {/* Show either the new image preview or the existing profile image */}
              {profileImagePreview && (
                <div style={{ marginTop: "10px" }}>
                  <Typography>Preview:</Typography>
                  <img
                    src={profileImagePreview} // Use profileImagePreview, which is set from either the new image or profile_url
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
              <Button type="button" variant="contained" onClick={handleCancel} color="primary" sx={{mx:2, backgroundColor:'#e0e0e0'}}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        // open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditTeacherPage;
