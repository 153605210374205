import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  TextField,
  Container,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const AssignDepartment = () => {
  const navigate = useNavigate();
  const [newDepartment, setNewDepartment] = useState({
    // resource_person: "",
    // department: "",
    department_id: "", // Initialize grade_id state
    teacher_id: "",
    labname_id: "",
    lab_location: "",
  });

  const [error, setError] = useState(""); // State for error handling
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [totalItems, setTotalItems] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [alreadySelectedProducts, setAlreadySelectedProducts] = useState([]);
  const [resourcePerson, setResourcePerson] = useState([]);
  const [loading, setLoading] = useState(true);
  const [labnames, setLabNames] = useState([]);
  const [alreadyAssignedTeachers, setAlrteadyAssignedTeachers] = useState([]);

  // fetch teachers by department
  useEffect(() => {
    const fetchTeachersByDept = async () => {
      if (!newDepartment.department_id) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/teachersbydepartment/${newDepartment.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setTeachers(data.data || []);
          console.log("teachers related department: ", data.data);
          setNewDepartment.department_id = "";
        }

        const response1 = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/alreadyAssignedTeachersbydepartment/${newDepartment.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data1 = await response1.json();
        if (response.ok) {
          setAlrteadyAssignedTeachers(data1.data || []);
          console.log("teachers related department: ", data1.data);
          setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachersByDept();
  }, [newDepartment.department_id]);

  useEffect(() => {
    const fetchLabnamesByDept = async () => {
      if (!newDepartment.department_id) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/labnames/${newDepartment.department_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setLabNames(data.data || []);
          console.log("teachers related department: ", data.data);
          setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchLabnamesByDept();
  }, [newDepartment.department_id]);

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data); // Log the data to check its structure
        if (response.data.data) {
          setDepartments(response.data.data || []);
        } else {
          console.error("Expected an array but received:", response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching departments", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  // Handle form submission
  const handleSubmit = async () => {
    const token = getToken();
    if (!token) {
      console.log("Unauthorized User");
      return;
    }

    // Validation: Check if at least one product is selected

    // setError(""); // Clear error if validation passes
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_department`,
        newDepartment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate("list");
    } catch (err) {
      console.error(err);
      // Optionally handle error
    }
  };

  const handleCancel = () => {
    navigate("/school/assign-department/list"); // Navigate to the desired route on cancel
  };

  return (
    <Container maxWidth="100%">
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4">Assign Department</Typography>
      </Box>
      <Box>
        <Grid
          container
          spacing={2}
          // ml={0}
          sx={{ marginTop: "20px", width: "80%" }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="#000">
              Select Department
            </Typography>

            {/* <Grid item xs={12} md={6}> */}
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel>Departments</InputLabel>
              <Select
                value={newDepartment.department_id}
                // onChange={(e) =>
                //   setNewDepartment({ ...newDepartment, department_id: e.target.value })
                // }

                onChange={(e) => {
                  setNewDepartment({
                    ...newDepartment,
                    department_id: e.target.value,
                  });
                  setTeachers([]); // Uncomment if needed
                }}
                label="Department"
                aria-required="true"
              >
                {/* {departments.map((department) => (
                  <MenuItem key={department.department_id} value={department._id}>
                    {department.department_name
                      ? department.department_name
                      : null}
                  </MenuItem>
                ))} */}
                {departments.map((department, index) => (
                  <MenuItem key={index} value={department.department_id}>
                    {department.department_name}{" "}
                    {/* Display the department name */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display={"flex"} flexDirection="column">
              <FormControl component="fieldset" margin="normal" required>
                <Typography>Select Teacher</Typography>
                <RadioGroup
                  value={newDepartment.teacher_id} // This should hold the selected teacher's ID
                  onChange={(e) =>
                    setNewDepartment({
                      ...newDepartment,
                      teacher_id: e.target.value,
                    })
                  }
                >
                  {teachers.map((teacher) => (
                    <FormControlLabel
                      key={teacher._id}
                      control={<Radio />}
                      value={teacher._id} // Use teacher's ID as the radio value
                      label={
                        teacher && teacher.name
                          ? teacher.name
                          : "Unnamed teacher"
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal" required>
                <Typography>Already Assigned Teacher</Typography>

                <ul>
                  {alreadyAssignedTeachers.map((teacher) => (
                    <li key={teacher._id}>
                      {teacher && teacher.teacher_id?.name
                        ? teacher.teacher_id.name
                        : "N/A"}
                    </li>
                  ))}
                </ul>
              </FormControl>
            </Box>

            {labnames && labnames.length > 0 ? (
              <>
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                >
                  <InputLabel>Lab Names</InputLabel>
                  <Select
                    value={newDepartment.labname_id}
                    onChange={(e) =>
                      setNewDepartment({
                        ...newDepartment,
                        labname_id: e.target.value,
                      })
                    }
                    label="Lab Name"
                    aria-required="true"
                  >
                    {labnames.map((labname) => (
                      <MenuItem key={labname._id} value={labname._id}>
                        {labname.lab_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Lab location"
                  value={newDepartment.lab_location}
                  onChange={(e) =>
                    setNewDepartment({
                      ...newDepartment,
                      lab_location: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
              </>
            ) : (
              <p></p>
            )}
          </Grid>

          <Grid item xs={12} md={6}></Grid>
        </Grid>

        <Box mt={3}>
          {error && <Typography color="error">{error}</Typography>}{" "}
          {/* Display error message */}
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            ADD
          </Button>
          <Button
            variant="contained"
            sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AssignDepartment;
