import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { getToken } from "../../../utils/AuthUtils";
import { Box } from "@mui/system";

const AddQuiz = () => {
  const navigate = useNavigate();

  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [questions, setQuestions] = useState([
    {
      question_text: "",
      question_type: "mcq",
      points: "",
      options: ["", "", "", ""],
      answer: "",
    },
  ]);
  const [totalScore, setTotalScore] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  // Fetch quiz categories
  useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quiz/category`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz subcategories:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/getAlldepartment`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setDepartments(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Fetch courses based on selected department
  useEffect(() => {
    const fetchCourses = async () => {
      if (!selectedDepartment) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/courses/${selectedDepartment._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setCourses(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [selectedDepartment]);

  // Handle change for question fields (question text, points, options, etc.)
  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuestions = [...questions];
    if (name.startsWith("option_")) {
      const optionIndex = parseInt(name.split("_")[1]) - 1;
      updatedQuestions[index].options[optionIndex] = value;
    } else if (name === "question_type") {
      updatedQuestions[index][name] = value;
      if (value === "yes/no") {
        updatedQuestions[index].options = ["yes", "no"]; // Simplified for yes/no
      } else {
        updatedQuestions[index].options = ["", "", "", ""]; // Reset for MCQ
      }
    } else {
      updatedQuestions[index][name] = value;
    }
    setQuestions(updatedQuestions);
  };

  // Add new question to the form
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        question_text: "",
        question_type: "mcq",
        points: "",
        options: ["", "", "", ""],
        answer: "",
      },
    ]);
  };

  // Remove question from the form
  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      setQuestions(questions.filter((_, i) => i !== index));
    }
  };

  // Submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const totalPoints = questions.reduce((sum, question) => sum + Number(question.points), 0);
    if (Number(totalScore) !== totalPoints) {
      setError("Total score and sum of points do not match.");
      return;
    }

    const quizData = {
      catId: selectedQuizCategory,
      subcatId: selectedQuizSubCategory,
      department: selectedDepartment,
      course: selectedCourse,
      questions,
      total_score: totalScore,
      quiz_title: title,
    };

    setLoading(true);

    try {
      const token = getToken();
      if (!token) {
        setError("Unauthorized user.");
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/quiz`, quizData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status===201) {
        setSuccess("Quiz successfully created!");
        resetForm();
      } else {
        setError("Failed to create quiz.");
      }
    } catch (err) {
      setError("An error occurred while creating the quiz.");
    } finally {
      setLoading(false);
    }
  };

  // Reset form to initial state
  const resetForm = () => {
    setSelectedQuizCategory(null);
    setSelectedQuizSubCategory(null);
    setSelectedDepartment(null);
    setSelectedCourse(null);
    setTotalScore("");
    setTitle("");
    setQuestions([
      {
        question_text: "",
        question_type: "mcq",
        points: "",
        options: ["", "", "", ""],
        answer: "",
      },
    ]);
  };

  // Close Snackbar (success or error)
  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Create Quiz
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Quiz Category */}
            <Grid item xs={12}>
              <Autocomplete
                options={quizCategory}
                getOptionLabel={(option) => option.quiz_category_name || "N/A"}
                value={selectedQuizCategory || null}
                onChange={(e, newValue) => setSelectedQuizCategory(newValue)}
                renderInput={(params) => <TextField {...params} label="Select quiz category" />}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Quiz Sub Category */}
            <Grid item xs={12}>
              <Autocomplete
                options={quizSubCategory}
                getOptionLabel={(option) => option.quiz_sub_category_name || "N/A"}
                value={selectedQuizSubCategory || null}
                onChange={(e, newValue) => setSelectedQuizSubCategory(newValue)}
                renderInput={(params) => <TextField {...params} label="Select quiz sub category" />}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Department */}
            <Grid item xs={12}>
              <Autocomplete
                options={departments}
                getOptionLabel={(option) => option.department_name || "N/A"}
                value={selectedDepartment || null}
                onChange={(e, newValue) => setSelectedDepartment(newValue)}
                renderInput={(params) => <TextField {...params} label="Select department" />}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Course */}
            <Grid item xs={12}>
              <Autocomplete
                options={courses}
                getOptionLabel={(option) => option.courses_name || "N/A"}
                value={selectedCourse || null}
                onChange={(e, newValue) => setSelectedCourse(newValue)}
                renderInput={(params) => <TextField {...params} label="Select course" />}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                label="Quiz Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>

            {/* Total Score */}
            <Grid item xs={12} md={12}>
              <TextField
                label="Total Score"
                type="number"
                value={totalScore}
                onChange={(e) => setTotalScore(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>

          {/* Questions */}
          <Typography variant="h5" gutterBottom>
            Questions
          </Typography>

          {questions.map((question, index) => (
            <Grid container spacing={2} key={index} sx={{ border: "1px solid #ccc", padding: "16px", marginBottom: "16px" }}>
              <Grid item xs={12}>
                <TextField
                  name="question_text"
                  label="Question Text"
                  value={question.question_text}
                  onChange={(e) => handleQuestionChange(index, e)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="points"
                  label="Points"
                  type="number"
                  value={question.points}
                  onChange={(e) => handleQuestionChange(index, e)}
                  fullWidth
                  required
                />
              </Grid>

              {/* Options */}
              {question.options.map((option, idx) => (
                <Grid item xs={12} key={idx}>
                  <TextField
                    name={`option_${idx + 1}`}
                    label={`Option ${idx + 1}`}
                    value={option}
                    onChange={(e) => handleQuestionChange(index, e)}
                    fullWidth
                    required={idx < 2}
                  />
                </Grid>
              ))}

              {/* Answer */}
              <Grid item xs={12}>
                <TextField
                  name="answer"
                  label="Answer"
                  value={question.answer}
                  onChange={(e) => handleQuestionChange(index, e)}
                  fullWidth
                />
              </Grid>

              {/* Remove question */}
              <Grid item xs={12}>
                <IconButton onClick={() => handleRemoveQuestion(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          {/* Buttons */}
          <Box p={1}>
            <Button onClick={handleAddQuestion} variant="contained" color="primary">
              Add Question
            </Button>

            <Button type="submit" variant="contained" color="primary" sx={{ background: "#e0e0e0", marginLeft: "10px" }}>
              Submit
            </Button>

            <Button onClick={() => navigate("/partner/assessment_list")} variant="outlined" color="secondary" sx={{ marginLeft: "10px" }}>
              Cancel
            </Button>
          </Box>
        </form>
      )}

      {/* Success Snackbar */}
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddQuiz;
