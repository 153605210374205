import React, { useState } from "react";
import { Box, Typography, IconButton, Paper } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { useLocation, useNavigate } from "react-router-dom";

function AssessmentSubmitted() {
  const [rating, setRating] = useState(0);
  const [isSaved, setIsSaved] = useState(false); // To track if points are saved

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = location.state || {}; 

  // Function to handle star click
  const handleRating = async (index) => {
    const newRating = index + 1; // Ratings are 1-indexed
    setRating(newRating);

    // Save points (you can replace this with an API call)
    const points = calculatePoints(newRating);
    await savePoints(points);
    setIsSaved(true); // Indicate points are saved
  };

  const calculatePoints = (stars) => stars * 2; // Each star is worth 2 points

  const savePoints = async (points) => {
    try {
      const token = getToken();
        if(!token){
            console.log("Unauthorized Access");
            return;
        }
      // Example: Send points to an API
      // Replace the URL and payload with your actual API details
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assessment/add-assessment-rating`,{
          points, id
        },
        {
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              },
              }
      );

      if (!response.ok) {
        throw new Error("Failed to save points");
      }
      console.log("Points saved successfully:", points);
    } catch (error) {
      console.error("Error saving points:", error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        color: "#fff",
        padding: 2,
        backgroundColor: "#51cd51"
      }}
    >
      <Paper
        elevation={6}
        sx={{
          width: "100%",
          maxWidth: "400px",
          padding: 4,
          textAlign: "center",
          borderRadius: 2,
          color: "#fff",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#4CAF50",
            mb: 2,
          }}
        >
          ✔ Assessment Submitted!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, color: "#999999" }}>
          Thank you for submitting your assessment. Your feedback is important
          to us.
        </Typography>

        {/* Star Rating Section */}
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            Rate Your Experience:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            {[...Array(5)].map((_, index) => (
              <IconButton
                key={index}
                onClick={() => handleRating(index)}
                sx={{
                  color: index < rating ? "#FFD700" : "#CCC",
                  transition: "color 0.3s",
                }}
              >
                <StarIcon fontSize="large" />
              </IconButton>
            ))}
          </Box>

          {/* Display Points */}
          {rating > 0 && (
            <Typography
              variant="body2"
              sx={{ mt: 2, fontSize: "1rem", color: isSaved ? "#4CAF50" : "#FF5722" }}
            >
              {isSaved
                ? `You rated ${rating} star(s), earning ${calculatePoints(rating)} points. Points saved successfully!`
                : `You rated ${rating} star(s), earning ${calculatePoints(rating)} points. Saving...`}
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

export default AssessmentSubmitted;
