import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { getToken } from "../../../utils/AuthUtils";

const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [evaluatedAssessment, setEvaluatedAssessment] = useState([]);
  const location = useLocation();
  const { gradename, course_name, chapterindex, chapter_namee, unitnamee } =
    location.state || {};
  const navigate = useNavigate();
  const [marks, setMarks] = useState({});
  const { unitid, id } = useParams(); // both unitid and id for fetching assessment and evaluated data

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/byunitId/${unitid}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        const assessmentData = response.data.data || [];
        setAssessmentQuestions(
          Array.isArray(assessmentData) ? assessmentData : []
        );
      } catch (error) {
        console.error("Error fetching assessment questions:", error);
      }
    };
    fetchAssessmentQuestions();
  }, [unitid]);

  useEffect(() => {
    const fetchEvaluatedAssessment = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/evaluated/${id}`,
          { headers: { Authorization: `Bearer ${getToken()}` } }
        );
        const evaluatedAssessmentData = response.data.data || [];
        setEvaluatedAssessment(evaluatedAssessmentData);
      } catch (error) {
        console.error("Error fetching evaluated assessment:", error);
      }
    };
    fetchEvaluatedAssessment();
  }, [id]);

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleMarksChange = (questionId, value, maxPoints) => {
    const enteredMarks = parseInt(value, 10);
    if (enteredMarks > maxPoints) {
      // alert(`Entered marks exceed the allowed points. Max points: ${maxPoints}`);
      return;
    }
    setMarks({
      ...marks,
      [questionId]: value,
    });
  };

  // Function to get evaluated answer for each question
  const getEvaluatedAnswer = (questionId) => {
    if (evaluatedAssessment.answers) {
      const answer = evaluatedAssessment.answers.find(
        (a) => a.question_id === questionId
      );
      return answer ? answer : null;
    }
    return null;
  };

  return (
    <Container maxWidth="100%">
      {/* Breadcrumb Section */}
      <Box sx={{ backgroundColor: "#9e503d", height: "50px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: "18px", color: "#fff !important" }}
            >
              {gradename}
              <ArrowRightIcon sx={{ color: "#fff" }} /> {course_name}
              <ArrowRightIcon sx={{ color: "#fff" }} /> {chapterindex}
              <ArrowRightIcon sx={{ color: "#fff" }} /> {chapter_namee}
              <ArrowRightIcon sx={{ color: "#fff" }} /> {unitnamee}
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundColor: "#f1f1f1",
          height: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          marginBottom: "30px",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h3">MCQ Type Questions</Typography>
        </Container>
      </Box>

      {/* Questions Section */}
      {Array.isArray(assessmentQuestions) && assessmentQuestions.length > 0 ? (
        assessmentQuestions.map((q, index) => {
          const evaluatedAnswer = getEvaluatedAnswer(q._id); // Get evaluated answer for this question

          return (
            <Box key={index} mb={2}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
                {`${index + 1}. ${q.question_text}`}
              </Typography>
              {evaluatedAnswer && (
                <Box>
                  {/* Display options for mcq type questions */}
                  {q.question_type === "mcq" && (
                    <RadioGroup
                      name={`question-${index}`}
                      //   value={selectedAnswers[index] || ""}
                      value={evaluatedAnswer.user_answer || ""}
                      onChange={(e) =>
                        handleAnswerChange(index, e.target.value)
                      }
                    >
                      {q.options.map((option, i) => (
                        <FormControlLabel
                          key={i}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  )}

                  {/* Display yes/no options */}
                  {q.question_type === "yes/no" && (
                    <RadioGroup
                      name={`question-${index}`}
                      //   value={selectedAnswers[index] || ""}
                      value={evaluatedAnswer.user_answer || ""}
                      onChange={(e) =>
                        handleAnswerChange(index, e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}

                  {/* Display textarea for descriptive type questions */}
                  {/* {q.question_type === "descriptive" && (
                    <textarea
                      style={{ width: "100%", border: "1px solid" }}
                    //   value={selectedAnswers[index] || ""}
                    value={evaluatedAnswer.user_answer || ""}
                      onChange={(e) => handleAnswerChange(index, e.target.value)}
                    />
                  )} */}

                  {q.question_type === "descriptive" && (
                    <>
                      <textarea
                        style={{ width: "100%", border: "1px solid" }}
                        value={evaluatedAnswer.user_answer || ""}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                      />
                      <Box mt={2}>
                        <Typography>Enter Marks (Max: {q.points}):</Typography>
                        <TextField
                          type="number"
                          // value={marks[q._id] || ""}
                          value={evaluatedAnswer.points || ""}
                          onChange={(e) =>
                            handleMarksChange(q._id, e.target.value, q.points)
                          }
                          inputProps={{ min: 0, max: q.points }}
                          variant="outlined"
                        />
                      </Box>
                    </>
                  )}

                  {/* Display evaluated answers if available */}

                  {
                    <Box mt={2}>
                      <Typography
                        color={evaluatedAnswer.is_correct ? "green" : "red"}
                      >
                        Your Answer: {evaluatedAnswer.user_answer}
                      </Typography>
                      {!evaluatedAnswer.is_correct && (
                        <Typography color="green">
                          Correct Answer: {evaluatedAnswer.correct_answer}
                        </Typography>
                      )}
                      <Typography>Points: {evaluatedAnswer.points}</Typography>
                    </Box>
                  }
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <Typography>No questions available.</Typography>
      )}
    </Container>
  );
};

export default Assessment;
