import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
  Grid
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";

const QuizList = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId: ""
  });
  const [quizCategory, setQuizCategory] = useState([]);
  const [selectedQuizCategory, setSelectedQuizCategory] = useState(null);
  const [quizSubCategory, setQuizSubCategory] = useState([]);
  const [selectedQuizSubCategory, setSelectedQuizSubCategory] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigate = useNavigate();

   // Fetch quiz categories
   useEffect(() => {
    const fetchQuizCategory = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/quiz/category`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setQuizCategory(data.data || []);
        }
      } catch (error) {
        console.error("Error fetching quiz categories:", error);
      }
    };
    fetchQuizCategory();
  }, []);

  // Fetch quiz sub-categories based on selected quiz category
  useEffect(() => {
    const fetchQuizSubCategory = async () => {
      if (!selectedQuizCategory) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/subcategory/${selectedQuizCategory._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setQuizSubCategory(data.data || []);
        }

        // fetch table
        const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbycatId/${selectedQuizCategory._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }

      } catch (error) {
        console.error("Error fetching quiz list:", error);
      }
    };
    fetchQuizSubCategory();
  }, [selectedQuizCategory]);

  useEffect(()=>{
    fetchQuizListBySubCatId();
  },[selectedQuizSubCategory])

  const fetchQuizListBySubCatId = async () => {
    try {
        const token = getToken();

        // fetch table
        const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbysubcatId/${selectedQuizSubCategory._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }

      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
  

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/getAlldepartment`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setDepartments(data.data || []);
        }

      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);


  useEffect(()=>{
    fetchQuizListByCourseId();
  },[selectedCourse])

  const fetchQuizListByCourseId = async () => {
    try {
        const token = getToken();

        // fetch table
        const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbycourseId/${selectedQuizSubCategory._id}/${selectedDepartment._id}/${selectedCourse._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }

      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

  // Fetch courses based on selected department
  useEffect(() => {
    const fetchCourses = async () => {
      if (!selectedDepartment) return;
      try {
        const token = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/quiz/courses/${selectedDepartment._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setCourses(data.data || []);
        }

        // fetch table data
        const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/quiz/allquizbydeptId/${selectedQuizSubCategory._id}/${selectedDepartment._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data1 = await response1.json();
      if (data1?.data) {
        const quizzesWithSerial = data1.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data1.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [selectedDepartment]);

  useEffect(() => {
    fetchQuizzes();
  }, [page, rowsPerPage, searchTerm, forFilter]);

  


  const fetchQuizzes = async () => {
    try {
      const token = getToken();
      if (!token) return;
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/quiz/quizlist?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}&course=${forFilter.course}&grade=${forFilter.grade}&studentId=${forFilter.studentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        }
      );
      const data = await response.json();
      if (data?.data) {
        const quizzesWithSerial = data.data.map((quiz, index) => ({
          ...quiz,
          serial: page * rowsPerPage + index + 1,
        }));
        setQuizes(quizzesWithSerial);
        setTotalItems(data.pagination.totalItems);
      } else {
        setError("No quizzes found");
      }
    } catch (err) {
      setError("Failed to fetch quizzes");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (field) => (event) => {
    setForFilter((prev) => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSnackbarClose = () => setOpenSnackbar(false);

  const handleAddQuiz = () => {
    navigate("/add-quiz");
  };

  return (
    <Box>
      <h2>Quiz List</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search"
            variant="outlined"
            size="large"
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={4}>
        <Grid item xs={12}>
              <Autocomplete
                options={quizCategory}
                getOptionLabel={(option) => option.quiz_category_name || "N/A"}
                value={selectedQuizCategory || null}
                onChange={(e, newValue) => setSelectedQuizCategory(newValue)}
                renderInput={(params) => <TextField {...params} label="Select quiz category" />}
                sx={{ width: "100%" }}
              />
            </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          {/* Quiz Sub Category */}
          <Grid item xs={12}>
              <Autocomplete
                options={quizSubCategory}
                getOptionLabel={(option) => option.quiz_sub_category_name || "N/A"}
                value={selectedQuizSubCategory || null}
                onChange={(e, newValue) => setSelectedQuizSubCategory(newValue)}
                renderInput={(params) => <TextField {...params} label="Select quiz sub category" />}
                sx={{ width: "100%" }}
              />
            </Grid>
        </Grid>

         {/* Department */}
         <Grid item xs={12} sm={4}>
              <Autocomplete
                options={departments}
                getOptionLabel={(option) => option.department_name || "N/A"}
                value={selectedDepartment || null}
                onChange={(e, newValue) => setSelectedDepartment(newValue)}
                renderInput={(params) => <TextField {...params} label="Select department" />}
                sx={{ width: "100%" }}
              />
            </Grid>

            {/* Course */}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                options={courses}
                getOptionLabel={(option) => option.courses_name || "N/A"}
                value={selectedCourse || null}
                onChange={(e, newValue) => setSelectedCourse(newValue)}
                renderInput={(params) => <TextField {...params} label="Select course" />}
                sx={{ width: "100%" }}
              />
            </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Serial</b></TableCell>
              <TableCell><b>Quiz Title</b></TableCell>
              <TableCell><b>Quiz Category</b></TableCell>
              <TableCell><b>Quiz Subcategory</b></TableCell>
              <TableCell><b>Department</b></TableCell>
              <TableCell><b>Course</b></TableCell>
              <TableCell><b>Total Score</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
              <TableCell><b>Date</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quizes.length > 0 ? (
              quizes.map((quiz) => (
                <TableRow key={quiz._id}>
                  <TableCell>{quiz.serial}</TableCell>
                  <TableCell>{quiz?.quiz_title || "Unknown Title"}</TableCell>
                  <TableCell>{quiz?.quizcategory?.quiz_category_name || "Unknown Title"}</TableCell>
                  <TableCell>{quiz.quizsubcategory?.quiz_sub_category_name || "Unknown Course"}</TableCell>
                  <TableCell>{quiz.departmentdata?.department_name || "Unknown Course"}</TableCell>
                  <TableCell>{quiz.coursedata?.courses_name || "Unknown Course"}</TableCell>
                  <TableCell>{quiz?.quiz_score || "N/A"}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => navigate(`/quiz/${quiz._id}`)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{new Date(quiz.createdAt).toLocaleDateString()}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">No quizzes available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddQuiz}
        sx={{ mt: 3 }}
      >
        Add New Quiz
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QuizList;
